<!-- 版本管理 -->
<template>
  <div class="outContainer">
    <!-- 筛选条件栏 -->
    <div class="search">
      <el-select class='select each' v-model="searchInfo.param" placeholder="设备类型" @change="changeDeviceType">
        <el-option
          v-for="item in equipTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          >
        </el-option>
      </el-select>
      <el-button size="mini" type="primary" @click="openVersionDialog(null)" style="margin-left: 20px;">新增硬件版本</el-button>
    </div>
    <!-- 表格 -->
    <div class="tableContainer">
      <el-table
       :data="tableData"
       height="100%"
       style="width: 100%;">
        <el-table-column align="center" label="硬件版本号" prop="version">
        </el-table-column>
        <el-table-column align="center" label="设备类型" prop="equipType">
          <template slot-scope="scope">
            {{ scope.row.deviceType == 1 ? '黑匣子' : (scope.row.deviceType == 2 ? '感应器设备' : (scope.row.deviceType == 3 ? '道闸设备' : (scope.row.deviceType == 4 ? 'WiFi开关' : (scope.row.deviceType == 5 ? 'WiFi温感' : '--')))) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime">
        </el-table-column>
        <el-table-column align="center" label="操作" width="300" fixed="right">
          <template slot-scope="scope" >
            <el-button size="mini" @click="handleSoftwareList(scope.row)">软件版本列表</el-button>
            <el-button size="mini" @click="handleAdd(scope.row)">新增软件版本</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 新增软件版本 -->
    <el-drawer title="新增软件版本"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
      size="50%">
      <add v-if="addDrawer" @close='handleClose' :row="row" ref='newForm'></add>
    </el-drawer>
    <!-- 新增硬件版本 -->
    <el-dialog title="新增硬件版本" :visible.sync="VersionVisible" >
      <el-form ref="versionForm" :model="versionForm" :rules="rules">
        <el-form-item label="版本号" label-width="100px" prop="version">
          <el-input v-model="versionForm.version" style="width: 100%;"></el-input>
        </el-form-item>
        <el-form-item  label="设备类型"  label-width="100px" prop="deviceType">
          <el-radio-group v-model="searchInfo.param">
            <el-radio style="height: 40px;line-height: 40px;"  :label="1" disabled>车载设备</el-radio>
            <el-radio style="height: 40px;line-height: 40px;"  :label="2" disabled>感应器设备</el-radio>
            <el-radio style="height: 40px;line-height: 40px;"  :label="3" disabled>道闸设备</el-radio>
            <!-- <el-radio style="height: 40px;line-height: 40px;"  :label="4" disabled>WiFi开关</el-radio>
            <el-radio style="height: 40px;line-height: 40px;"  :label="5" disabled>WiFi温感</el-radio> -->
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAddHard">取 消</el-button>
        <el-button type="primary" @click="addHardVersion">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 软件版本 -->
    <el-dialog title="软件版本" :visible.sync="softwareVisible" width="80%" style="padding: 25px;">
      <el-table
       border
       :header-cell-style="{background:'#DDE9F2'}"
       :data="softwareTable">
        <el-table-column align="center" prop="version" label="软件版本号"></el-table-column>
        <el-table-column align="center" prop="status" label="是否启用">
          <template slot="header" slot-scope="child">
            是否启用
            <el-switch
              v-model="softwareSearchInfo.param.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="changeStatus">
            </el-switch>
          </template>
          <template slot-scope="child">{{ child.row.status ? '启用' : '禁用' }}</template>
        </el-table-column>
        <el-table-column align="center" prop="createTime" label="创建时间"></el-table-column>
        <el-table-column align="center" label="操作" fixed="right" min-width="120">
          <template slot-scope="child" >
            <el-button size="mini" @click="handleLock(child.row)">{{ child.row.status ? '禁用' : '启用' }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <pagination v-bind:child-msg="softwareSearchInfo" @callFather="callFather"></pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import add from './add.vue';
import { getPageHardwareVersion,addHardwareVersion,getListSoftwareVersion,updateSoftwareVersion } from '@/api/public.js';
import { timeChange,validateVersion } from '@/api/publicFun.js';
import pagination from '@/components/Pagination.vue';
export default {
  data() {
    return {
      searchInfo:{ // 硬件版本筛选条件
        param:1,
      },
      softwareSearchInfo:{ // 软件版本筛选条件
        pageNo:1,
        pageSize:10,
        total:0,
        param:{
          hardwareId:'',
          status:true,
        },
      },
      tableData: [], // 硬件版本表格数据
      softwareTable:[], // 软件版本表格数据
      addDrawer:false, // 控制新增软件版本弹框是否显示
      direction:'rtl', // 侧边弹框弹出方向
      row:null, // 当前操作数据详情
      versionForm:{ // 新增硬件版本表单数据
        deviceType: 1,
        status: true,
        version: ""
      },
      deviceType:1, // 
      VersionVisible:false, // 控制新增硬件版本弹框是否显示
      softwareVisible:false, // 控制新增软件版本弹框是否显示
      equipTypeOptions:[ // 设备类型选项列表
        {value:1,label:'黑匣子'},
        {value:2,label:'感应器'},
        {value:3,label:'道闸'},
        // {value:4,label:'WiFi开关'},
        // {value:5,label:'WiFi温感'},
      ],
      rules: { // 表单验证
          version: [
            { required: true, message: '版本号不得为空', trigger: 'blur', },
            { validator:validateVersion, trigger: 'blur' }
          ],
          // deviceType: [
          //   { required: true, message: '请选择设备类型', trigger: 'blur', },
          // ],
      },
    }
  },

  components: {add,pagination},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 切换设备类型
    changeDeviceType(e){
      this.searchInfo.param = e;
      this.init();
    },
    // 关闭侧边弹框
    handleClose(){
      this.addDrawer = false;
      this.row = null;
    },
    // 初始化获取列表数据
    async init(){
      await getPageHardwareVersion(this.searchInfo).then(res=>{
        if(res.success){
          this.tableData = res.data;
          this.tableData.forEach(element => {
            let eachTime = timeChange(element.createTime);
            element.createTime = `${eachTime.year}-${eachTime.month}-${eachTime.day} ${eachTime.hour}:${eachTime.minutes}:${eachTime.seconds}`;
          });
        }else{
          this.tableData = [];
        }
      })
    },
    // 获取硬件版本下的软件版本列表
    getSoftVersion(){
      getListSoftwareVersion(this.softwareSearchInfo).then(res=>{
        if(res.success){
          let child = res.data.list;
          this.softwareSearchInfo.total = res.data.total;
          child.forEach(element=>{
            let eachTime = timeChange(element.createTime);
            element.createTime = `${eachTime.year}-${eachTime.month}-${eachTime.day} ${eachTime.hour}:${eachTime.minutes}:${eachTime.seconds}`;
          })
          this.softwareTable = child;
        }else{
          this.softwareTable = [];
          this.softwareSearchInfo.total = 0;
        }
      })
    },
    // 打开软件版本列表弹框
    handleSoftwareList(row){
      this.softwareSearchInfo.param.hardwareId = row.id;
      this.getSoftVersion();
      this.softwareVisible = true;
    },
    // 筛选软件版本是否启用
    changeStatus(e){
      this.softwareSearchInfo.param.status = e;
      this.getSoftVersion();
    },
    // 打开新增软件版本弹框
    handleAdd(row){
      this.row = row;
      this.addDrawer = true;
    },
    // 确认新增硬件版本
    addHardVersion(){
      this.$refs["versionForm"].validate( valid => {
        if(valid){
          this.versionForm.deviceType = this.searchInfo.param;
          this.$confirm('确认提交吗？').then(async() => {
            await addHardwareVersion({param:this.versionForm}).then(res=>{
              if(res.success){
                this.$message({
                  message:'提交成功',
                  type:'success'
                })
                this.init();
                this.VersionVisible = false;
              }else{
                this.$message({
                  message:res.msg,
                  type:'success'
                })
              }
            })
            this.row = null;
          })
        }
      })
    },
    // 取消新增
    cancelAddHard(){
      this.row = null;
      this.VersionVisible = false;
    },
    // 打开编辑
    openVersionDialog(row){
      this.row = row;
      this.versionForm = row ? row : {};
      this.VersionVisible = true;
    },
    // 禁用 启用版本
    handleLock(row){
      this.$confirm('确认要进行该操作吗？').then(async () => {
        let data = {
          softwareId:row.id,
          status:!row.status,
        }
        await updateSoftwareVersion({param:data}).then(res=>{
            if(res.success){
              this.$message({
                message:'操作成功',
                type:'success'
              })
              this.getSoftVersion();
            }else{
              this.$message({
                message:res.msg,
                type:'fail'
              })
            }
          })
        })
    },
    // 分页插件事件
    callFather(parm) {
      this.searchInfo.pageNo = parm.currentPage;
      this.init();
    },
  },
};
</script>
<style scoped lang="scss">
/deep/.el-dialog{
  padding: 25px !important;
}
</style>
