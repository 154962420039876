<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="150px" style="width:95%;overflow:auto">
        <el-form-item label="对应硬件版本" prop="FirmVersion" >
          <p>{{row.version}}</p>
        </el-form-item>
        <el-form-item label="软件版本号" prop="version" >
          <el-input placeholder="请输入软件版本号" v-model="form.version">
          </el-input>
        </el-form-item>
        <el-form-item label="安装包" prop="uploadUrl" v-if="form.version != ''">
          <el-upload
            class="upload-demo"
            ref="upload"
            :http-request="handleHttpRequest"
            :action="form.uploadUrl"
            :on-change="handleChange"
            :before-upload="beforUpload"
            :on-success="handleSuccess"
            :on-error="handleError"
            :multiple="false"
            :auto-upload="false"
            :limit="1"
          >
            <el-button size="mini" v-if="form.version != ''">选取文件</el-button>
            <span v-else>请先输入软件版本号</span>
          </el-upload>
        </el-form-item>
        <el-form-item label="是否启用" prop="status" >
            <el-radio-group v-model="form.status">
              <el-radio-button  :label="true">启用</el-radio-button>
              <el-radio-button  :label="false">未启用</el-radio-button>
            </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="add('form')" :plain='true'>立即提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  import axios from 'axios';
  import { addSoftwareVersion,upload } from '@/api/public.js';
  import { validateVersion } from '@/api/publicFun.js'
  export default {
    props:['row'],
    data() {
      return {
        form:{ // 表单数据
          version:'',
          uploadUrl:"",
        },
        rules: { // 表单验证
            version: [
              { required: true, message: '软件版本号不得为空', trigger: 'blur', },
              { validator:validateVersion, trigger: 'blur' }
            ],
            uploadUrl: [
              { required: true, message: '请选取文件', trigger: 'blur', }
            ],
            status: [
              { required: true, message: '请选择是否启用', trigger: 'change', }
            ],
        },
        fileData:{},
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.form.hardwareId = this.row.id;
    },
  
    methods: {
      close(){
         this.$emit('close');
      },
      // 将上传文件的请求方式修改为PUT
      handleHttpRequest(options){
        const file = options.file
        axios.put(this.form.uploadUrl, file.slice(),{headers:{'Content-Type': 'application/octet-stream'}});
      },
      // 上传文件改变
      async handleChange(file){
          let data = {
            hardwareId:this.row.id,
            version:this.form.version,
            fileName:file.name
          }
          await upload({param:data}).then(res=>{
            if(res.success){
              this.form.uploadUrl = res.data.preUploadUrl;
              this.$nextTick(()=>{
                this.$refs.upload.submit();
              })
            }else{
              this.form.uploadUrl = "";
              this.$message({
                message:res.msg,
                type:"fail"
              })
            }
          })
      },
      beforUpload(file){},
      // 上传文件成功
      async handleSuccess(e){
        
      },
      // 上传文件失败
      handleError(e){
        this.$message({
          message:"上传文件失败，请重试",
          type:"fail"
        })
        this.form.uploadUrl = "";
      },
       //  添加
      add(form) {
         this.$refs["form"].validate(valid => {
            if (valid) {
                this.$confirm('确认新增吗？', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(async ()=>{
                  await addSoftwareVersion({param:this.form}).then((res) => {
                    if (res.success) {
                      this.$message({
                        message: "提交成功",
                        type: "success",
                      });
                      setTimeout(() => {
                        this.$emit("close");
                        this.init();
                      }, 1000);
                    }else{
                      this.$message({
                        message: res.msg,
                        type: "fail",
                      });
                    }
                  })
                  this.form.uploadUrl = "";
                })
            } else {
              return false;
            }
        });
      },
    }
  };
  </script>
  <style lang="scss" scoped>
   .el-drawer{
     overflow:auto !important;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  